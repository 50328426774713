<template>
  <div class="columns is-marginless is-multiline">
    <div class="column is-5-tablet is-4-desktop">
      <div class="columns is-multiline">
        <div class="column is-12">
          <profile-card :user-id="userId" />
        </div>
      </div>
    </div>

    <div class="column is-7-tablet is-8-desktop">
      <div class="columns is-multiline">
        <div v-if="isResellerUser" class="column is-12">
          <div class="columns is-multiline">
            <div class="column is-12">
              <change-password-card :user-id="userId" />
            </div>
          </div>
        </div>

        <div v-if="!isResellerUser" class="column is-12 is-6-widescreen">
          <div class="columns is-multiline">
            <div class="column is-12">
              <login-methods />
            </div>
          </div>
        </div>

        <div v-if="!isResellerUser" class="column is-12 is-6-widescreen">
          <div class="columns is-multiline">
            <div class="column is-12">
              <card>
                <template slot="header">
                  <p class="card-header-title">Support pin</p>
                </template>
                <template slot="body">
                  <support-pin-generate :user-id="userId" :focusable="false" />
                </template>
              </card>
            </div>
            <div class="column is-12">
              <contact-preferences />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountProfile",
  components: {
    "profile-card": () => import("@shared/account/_profileCard"),
    "change-password-card": () =>
      import("@shared/reseller/_changePasswordCard"),
    "contact-preferences": () => import("./_contactPreferences"),
    "login-methods": () => import("./_loginMethods"),
    "support-pin-generate": () => import("@shared/user/_supportPinGenerate")
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    isResellerUser() {
      return this.$store.getters["user/isResellerUser"]();
    }
  }
};
</script>

<style lang="scss">
@import "~@sass/bulma/custom-variables";

@include touch {
  .is-#1-touch {
    order: 1;
  }
}
</style>
